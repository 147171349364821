import { useGetForecast, useGetProject, useGetWeather } from "@/hooks/api";
import { Group, Image, Stack, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import CustomCard from "./CustomCard";

const WeatherCard = () => {
  const { projectId } = useParams();
  const { data: weatherData, isLoading: weatherLoading } = useGetWeather({
    pathParams: { projectId: projectId || "-1" },
  });
  const { data: forecastData, isLoading: forecastLoading } = useGetForecast({
    pathParams: { projectId: projectId || "-1" },
  });
  const { data: projectData, isLoading: projectLoading } = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  if (weatherLoading) return;
  if (weatherData === undefined) return;
  if (forecastLoading) return;
  if (forecastData === undefined) return;
  if (projectLoading) return;
  if (projectData === undefined) return;
  const tz = projectData.time_zone;

  function extractTime(datetime: number, timeZone: string): string {
    return dayjs.unix(datetime).tz(timeZone).format("ha");
  }

  return (
    <CustomCard
      beta={false}
      title="Weather & Forecast"
      showDownload={false}
      fill={false}
      style={{ width: "100%" }}
      header={true}
    >
      <Group grow style={{ height: "100%" }} justify="center">
        <Stack align="center" justify="center" gap={0}>
          <Text>{"Now"}</Text>
          <Image
            src={`https://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`}
            alt={weatherData.weather[0].description}
            style={{ width: "50px", height: "26px" }}
          />
          <Text>{`${Math.round(weatherData.main.temp)}°F`}</Text>
        </Stack>
        {forecastData.list.slice(0, 3).map((forecast, index) => (
          <Stack align="center" justify="center" gap={0} key={index}>
            <Text>{extractTime(forecast.dt, tz)}</Text>
            <Image
              src={`https://openweathermap.org/img/wn/${forecast.weather[0].icon}@2x.png`}
              alt={forecast.weather[0].description}
              style={{ width: "50px", height: "26px" }}
            />
            <Text>{`${Math.round(forecast.main.temp)}°F`}</Text>
          </Stack>
        ))}
      </Group>
    </CustomCard>
  );
};
export default WeatherCard;
