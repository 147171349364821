import CustomCard from "@/components/CustomCard";
import { PageError } from "@/components/Error";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import StatCard from "@/components/plots/StatCard";
import WeatherCard from "@/components/WeatherCard";
import {
  useGetEventCountTemp,
  useGetMeterPowerAndExpectedPower,
  useGetPaginatedEvents,
  useGetProject,
  useGetProjectPCSMechanicalAvailabilityData,
} from "@/hooks/api";
import { EventSummary, Quality, QualityLevel } from "@/hooks/types";
import {
  Badge,
  Card,
  Group,
  HoverCard,
  List,
  LoadingOverlay,
  rem,
  Space,
  Stack,
  Tabs,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from "@mantine/core";
import {
  IconCheck,
  IconExclamationMark,
  IconLetterQ,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PCSGISMap } from "./gis/pcs-gis";

interface jsonData {
  project_quality: number | null;
  project_mechanical_availability: number | null;
}

const PCSMechAvail = () => {
  const { projectId } = useParams();

  const { data, isLoading, error } = useGetProjectPCSMechanicalAvailabilityData(
    {
      pathParams: { projectId: projectId || "-1" },
      queryOptions: {
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000,
      },
    }
  );

  if (isLoading) return;
  if (!data) return;
  if (error) return;

  const latestData: jsonData = (
    data.json[data.json.length - 1] !== null
      ? data.json[data.json.length - 1]
      : { project_quality: 0, project_mechanical_availability: 0 }
  ) as jsonData;

  const getQualityLevel = (qualityValue: number): QualityLevel => {
    if (qualityValue >= 0.9) return "good";
    if (qualityValue >= 0.8) return "warning";
    return "bad";
  };
  const qualityData =
    latestData.project_quality !== null ? latestData.project_quality : 0;
  const availabilityData =
    latestData.project_mechanical_availability !== null
      ? latestData.project_mechanical_availability
      : 0;

  const createQuality = (qualityValue: number): Quality => {
    const level = getQualityLevel(qualityValue);
    const message = `Quality is ${(qualityValue * 100).toFixed(1)} %`;

    return {
      level,
      message,
      details: [
        {
          level,
          message,
        },
      ],
    };
  };

  const quality = createQuality(qualityData);
  return (
    <StatCard
      title="Yesterday's PCS Mechanical Availability"
      value={availabilityData * 100}
      suffix="%"
      info="Calculated as the ratio of the total number of hours PCSs were available to the total number of hours in the period."
      quality={quality} // Pass the quality data
    ></StatCard>
  );
};

const PCSAlerts = () => {
  const { projectId } = useParams();

  const { data, isLoading } = useGetEventCountTemp({
    pathParams: { projectId: projectId || "-1" },
  });

  if (isLoading) return;
  if (data === undefined) return;

  return <StatCard title="Offline PCSs" value={data}></StatCard>;
};

function QualityCard({ quality }: { quality: Quality }) {
  const colorMap = {
    good: "green",
    warning: "yellow",
    bad: "red",
  };

  const iconMap = {
    good: <IconCheck style={{ width: rem(16), height: rem(16) }} />,
    warning: (
      <IconExclamationMark style={{ width: rem(16), height: rem(16) }} />
    ),
    bad: <IconExclamationMark style={{ width: rem(16), height: rem(16) }} />,
  };

  return (
    <HoverCard shadow="md">
      <HoverCard.Target>
        <ThemeIcon color={colorMap[quality.level]} size={20} radius="xl">
          <IconLetterQ style={{ width: rem(16), height: rem(16) }} />
        </ThemeIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text>{quality.message}</Text>
        <Space h="xs" />
        <List spacing="xs" size="sm" center>
          {quality.details.map((detail, i) => (
            <List.Item
              key={i}
              icon={
                <ThemeIcon color={colorMap[detail.level]} size={20} radius="xl">
                  {iconMap[detail.level]}
                </ThemeIcon>
              }
            >
              {detail.message}
            </List.Item>
          ))}
        </List>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const PowerPlot = () => {
  const { projectId } = useParams();
  const theme = useMantineTheme();

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  const data = useGetMeterPowerAndExpectedPower({
    pathParams: { projectId: projectId || "-1" },
  });

  return (
    <CustomCard
      title="Meter Power"
      quality={data.data && <QualityCard quality={data.data.quality} />}
      style={{ height: "100%" }}
    >
      <PlotlyPlot
        data={data.data?.data.map((d) => ({
          x: d.x,
          y: d.y,
          name: d.name,
          hoverlabel: {
            namelength: -1,
          },
          fill: d.name === "Meter Active Power" ? "tozeroy" : "none",
          line: {
            color:
              d.name === "Meter Active Power"
                ? theme.colors.green[7]
                : theme.colors.orange[7],
          },
        }))}
        layout={
          project.data && {
            yaxis: {
              title: "Power (MW)",
              range: [
                project.data.project_type_id !== 1 ? undefined : 0,
                project.data.poi * 1.05,
              ],
            },
          }
        }
        isLoading={data.isLoading}
        error={data.error}
      />
    </CustomCard>
  );
};

const CurrentTime = ({ timezone }: { timezone: string }) => {
  const formattedTime = () => {
    return dayjs().tz(timezone).format("MMM D, YYYY HH:mm:ss z");
  };

  const [currentTime, setCurrentTime] = useState(formattedTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(formattedTime());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  });

  return <Text style={{ fontFamily: "monospace" }}>{currentTime}</Text>;
};

const EventTable = () => {
  const { projectId } = useParams();
  const showEnergy = "dollar";

  const [sortBy, setSortBy] = useState("loss_total_financial");

  const { data: topEvents, isLoading: isLoadingTopEvents } =
    useGetPaginatedEvents({
      pathParams: { projectId: projectId || "-1" },
      queryParams: {
        page: 1,
        page_size: 5,
        open: true,
        sort_column: sortBy,
        sort_direction: "desc",
        device_type_ids: [],
      },
      queryOptions: {
        staleTime: 60 * 1000,
      },
    });

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });
  const columnHelper = createMRTColumnHelper<EventSummary>();

  const columns = useMemo(() => {
    if (!topEvents) {
      return [];
    }
    return [
      columnHelper.accessor("device_name_full", {
        header: "Device",
        Cell: ({ row }) => (
          <Link
            to={`/projects/${projectId}/events/event/?eventId=${row.original.event_id}`}
            style={{ color: "inherit" }}
          >
            {row.original.device_name_full}
          </Link>
        ),
      }),

      columnHelper.accessor(
        (row) => {
          if (row.loss_daily_financial === 0) {
            return "-";
          } else {
            return `$${(row.loss_daily_financial ?? 0).toFixed(2)}`;
          }
        },

        {
          header: "Loss - Daily",
          enableSorting: true,
          mantineTableHeadCellProps: {
            align: "center",
          },
          mantineTableBodyCellProps: {
            align: "center",
          },
        }
      ),

      columnHelper.accessor(
        (row) => {
          if (row.loss_total_financial === 0) {
            return "-";
          } else {
            return `$${(row.loss_total_financial ?? 0).toFixed(2)}`;
          }
        },

        {
          header: "Loss - Total",
          enableSorting: true,
          mantineTableHeadCellProps: {
            align: "center",
          },
          mantineTableBodyCellProps: {
            align: "center",
          },
        }
      ),
    ];
  }, [topEvents, showEnergy, project.data?.time_zone, projectId]);
  const table = useMantineReactTable({
    columns,
    data: topEvents ?? [],
    enableStickyHeader: true,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    mantineTableContainerProps: {
      style: { maxHeight: "100%" },
    },
  });
  return (
    <Card
      withBorder
      radius="md"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Card.Section p={5}>
        <Group gap={3} pl="sm">
          <Text fw={500}>Top Events</Text>
          <Tabs
            defaultValue="loss_total_financial"
            value={sortBy}
            onChange={(value) => setSortBy(value ?? "loss_total_financial")}
          >
            <Tabs.List>
              <Tabs.Tab value="loss_total_financial">Total Loss</Tabs.Tab>
              <Tabs.Tab value="loss_daily">Daily Loss</Tabs.Tab>
            </Tabs.List>
          </Tabs>
        </Group>
      </Card.Section>
      <LoadingOverlay visible={isLoadingTopEvents} />
      <MantineReactTable table={table} />
    </Card>
  );
};

const ProjectHome = () => {
  const { projectId } = useParams();
  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  if (project.isLoading) return <PageLoader />;
  if (project.isError) return <PageError error={project.error} />;
  if (project.data === undefined) return <PageError error={undefined} />;

  return (
    <Stack p="md" h="100%">
      <Group>
        <Stack gap="xs" style={{ flex: 1 }}>
          <Title order={1}>{project.data?.name_long}</Title>
          <Group gap="xs">
            <Badge size="lg" radius="md">
              DC - {project.data.capacity_dc?.toFixed(1)} MW
            </Badge>
            <Badge size="lg" radius="md">
              AC - {project.data.capacity_ac?.toFixed(1)} MW
            </Badge>
            <Badge size="lg" radius="md">
              POI - {project.data.poi.toFixed(1)} MW
            </Badge>
          </Group>
        </Stack>
        <Card p={5} withBorder>
          <CurrentTime timezone={project.data?.time_zone} />
        </Card>
      </Group>
      <Group flex={1}>
        <Stack h="100%" flex={2}>
          <WeatherCard />
          <CustomCard title="Block Performance" fill style={{ flex: 1 }}>
            <PCSGISMap showTitleCard={false} />
          </CustomCard>
        </Stack>
        <Stack h="100%" flex={2}>
          <Group grow>
            <PCSMechAvail />
            <PCSAlerts />
          </Group>
          <EventTable />
          <PowerPlot />
        </Stack>
      </Group>
    </Stack>
  );
};

export default ProjectHome;
