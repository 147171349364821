import { useUser } from "@clerk/clerk-react";
import { Flex, Image, Text } from "@mantine/core";

const ProximalLogo = () => {
  return (
    <Flex
      style={{
        alignItems: "center",
        height: "100%",
      }}
      gap="md"
    >
      <Text fz="xl">
        <Text fw={700} span c="var(--mantine-primary-color-filled)" inherit>
          Proximal
        </Text>{" "}
        Energy
      </Text>
    </Flex>
  );
};

const Logo = () => {
  const { isSignedIn, user } = useUser();

  if (isSignedIn) {
    const parentCompany = user.publicMetadata.parent_company;
    if (parentCompany === "mccarthy") {
      return <Image src="/logo_mccarthy.png" h="75%" />;
    } else if (parentCompany === "catl") {
      return <Image src="/logo_catl.svg" h="50%" />;
    } else if (parentCompany === "excelsior") {
      return <Image src="/logo_excelsior.svg" h="70%" />;
    }
  }

  return <ProximalLogo />;
};

export default Logo;
