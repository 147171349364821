import { PageLoader } from "@/components/Loading";
import {
  useAddKPIAlert,
  useDeleteKPIAlert,
  useGetKPIAlerts,
  useGetKPIInstances,
} from "@/hooks/api";
import { KPIAlertProps, KPIInstanceProps } from "@/hooks/types";
import {
  Container,
  Title,
  Select,
  NumberInput,
  Checkbox,
  Button,
  Group,
  Stack,
  Text,
  Paper,
  TextInput,
  Modal,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface alertProps {
  project_id: string;
  alert_name: string;
  comparison: string | null;
  duration_value: string | null;
  kpi_type_id: string | null;
  notify: boolean;
  threshold_value: number | null | string;
  triggered: boolean | null;
}

const comparisonOptions = [
  { value: "gte", label: "Greater than or equal to" },
  { value: "gt", label: "Greater than" },
  { value: "eq", label: "Exactly" },
  { value: "lt", label: "Less than" },
  { value: "lte", label: "Less than or equal to" },
];

const dateOptions = ["Yesterday", "Last 7 days"];

const ProjectKPIAlerts = () => {
  const navigate = useNavigate();

  const { projectId } = useParams();
  const { mutate } = useAddKPIAlert();
  const { mutate: confirmDelete } = useDeleteKPIAlert();

  const [thresholdSuffix, setThresholdSuffix] = useState<string | null>("");

  const [submissionModalTitle, setSubmissionModalTitle] = useState("");
  const [
    submissionModalOpened,
    { open: openSubmissionModal, close: closeSubmissionModal },
  ] = useDisclosure(false);

  const [localAlertData, setLocalAlertData] = useState<KPIAlertProps[]>([]);

  const [
    deletionModalOpened,
    { open: openDeletionModal, close: closeDeletionModal },
  ] = useDisclosure(false);
  const [selectedKPIForDeletion, setSelectedKPIForDeletion] =
    useState<KPIAlertProps>();

  const { data: instanceData, isLoading: instanceLoading } = useGetKPIInstances(
    {
      pathParams: { projectId: projectId || "-1" },
      queryParams: { deep: true },
    }
  );
  const { data: alertData, isLoading: alertLoading } = useGetKPIAlerts({
    pathParams: { projectId: projectId || "-1" },
  });
  useEffect(() => {
    if (alertData) {
      setLocalAlertData(alertData);
    }
  }, [alertData]);

  const data = (instanceData?.map((item) => item.kpi_type) ??
    []) as KPIInstanceProps[];

  const kpiOptions = data?.map((item) => ({
    value: item.kpi_type_id.toString(),
    label: item.name_long,
  }));

  const form = useForm({
    initialValues: {
      project_id: projectId ?? "",
      alert_name: "",
      kpi_type_id: "",
      comparison: null,
      threshold_value: "",
      duration_value: null,
      notify: false,
      triggered: false,
    },

    validate: {
      alert_name: (value) => (value ? null : "Alert name is required"),
      kpi_type_id: (value) => (value ? null : "KPI is required"),
      comparison: (value) => (value ? null : "Comparison operator is required"),
      threshold_value: (value) => (value ? null : "Numeric value is required"),
      duration_value: (value) => (value ? null : "Over value is required"),
    },
  });

  const handleKpiChange = (value: any) => {
    form.setFieldValue("kpi_type_id", value ?? null);
    const selectedKpi = data?.find(
      (item) => item.kpi_type_id.toString() === value
    );
    if (selectedKpi?.unit === "%") {
      setThresholdSuffix("%");
    } else if (selectedKpi?.unit === "MWh") {
      setThresholdSuffix(" MWh");
    }
  };

  const handleSubmit = (values: alertProps) => {
    values.project_id = projectId ?? "-1";
    if (thresholdSuffix === "%") {
      values.threshold_value =
        typeof values.threshold_value === "number"
          ? values.threshold_value / 100
          : null;
    }
    mutate(values, {
      onSuccess: () => {
        setSubmissionModalTitle("Submission Successful");
        openSubmissionModal();
        form.reset();
      },
      onError: () => {
        setSubmissionModalTitle("Submission Error");
        openSubmissionModal();
      },
    });
  };

  const handleDelete = (selected_alert: KPIAlertProps) => {
    setSelectedKPIForDeletion(selected_alert);
    openDeletionModal();
  };

  const getKPIInfo = (kpi_type_id: string | null) => {
    const kpi = data.find(
      (item) => item.kpi_type_id.toString() === kpi_type_id
    );
    return kpi
      ? { name_long: kpi.name_long, unit: kpi.unit }
      : { name_long: "Unknown", unit: "" };
  };

  if (instanceLoading || alertLoading) return <PageLoader />;

  return (
    <Container fluid>
      <Modal
        opened={submissionModalOpened}
        onClose={() => closeSubmissionModal()}
        title={submissionModalTitle}
        centered
      >
        <Stack align="center">
          <Text>
            {submissionModalTitle === "Submission Successful"
              ? "Your alert was successfully created!"
              : "There was an error creating your alert. Please try again."}
          </Text>
          <Group>
            <Button onClick={() => closeSubmissionModal()}>Close</Button>
            <Button onClick={() => navigate(-1)}>Return</Button>
          </Group>
        </Stack>
      </Modal>

      <Modal
        opened={deletionModalOpened}
        onClose={closeDeletionModal}
        title={`Delete alert "${selectedKPIForDeletion?.config.alert_name}"?`}
        centered
      >
        <Stack align="center">
          <Text>Warning: this action cannot be undone.</Text>
          <Group>
            <Button onClick={closeDeletionModal}>Cancel</Button>
            <Button
              onClick={() => {
                confirmDelete({
                  project_id: projectId ?? "",
                  alert_id: selectedKPIForDeletion?.kpi_alert_id ?? -1,
                });
                closeDeletionModal();
              }}
            >
              Delete
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Group align="flex-start" pt="sm">
        <Paper withBorder p="sm" radius="md" w="65%">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
              <Title order={2}>New KPI Alert</Title>
              <Text size="lg">Alert Name</Text>
              <TextInput
                placeholder="Enter alert name..."
                required
                {...form.getInputProps("alert_name")}
              />
              <Text size="lg">I would like to be alerted when:</Text>
              <Group gap="md" grow>
                <Select
                  label="KPI"
                  placeholder="Select KPI..."
                  data={kpiOptions}
                  required
                  {...form.getInputProps("kpi_type_id")}
                  onChange={handleKpiChange}
                />

                <Select
                  label="Is"
                  placeholder="Select Operation..."
                  data={comparisonOptions}
                  required
                  {...form.getInputProps("comparison")}
                />

                <NumberInput
                  label="Threshold"
                  required
                  suffix={thresholdSuffix ?? ""}
                  {...form.getInputProps("threshold_value")}
                />
                <Select
                  label="During"
                  placeholder="Select Days..."
                  data={dateOptions}
                  required
                  {...form.getInputProps("duration_value")}
                />
              </Group>
              <Checkbox
                label="Notify via email"
                {...form.getInputProps("notify")}
              />
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </Paper>

        <Stack w="30%">
          <Paper withBorder p="xs" radius="md">
            <Title order={3}>Your Alerts:</Title>
            <Stack>
              {localAlertData?.map((alert) => {
                const kpiInfo = getKPIInfo(alert.config.kpi_type_id);
                const comparison_func = comparisonOptions.find(
                  (item) => item.value === alert.config.comparison
                )?.label;

                return (
                  <Paper key={alert.kpi_alert_id} withBorder p="xs" radius="sm">
                    <Group justify="space-between">
                      <Text>
                        <strong>{alert.config.alert_name}</strong>
                      </Text>
                      <Group>
                        <Button
                          size="compact-xs"
                          onClick={() => handleDelete(alert)}
                        >
                          <IconTrash size={20} />
                        </Button>
                      </Group>
                    </Group>
                    <Text>
                      Alerting when <em>{kpiInfo.name_long}</em> is{" "}
                      {comparison_func?.toLowerCase()}{" "}
                      {/* If kpiInfo unit is percent, multiply the value by 100.
                          Advanced logic is for type handling. */}
                      {kpiInfo.unit === "%"
                        ? typeof alert.config.threshold_value === "number"
                          ? alert.config.threshold_value * 100
                          : alert.config.threshold_value
                          ? parseFloat(alert.config.threshold_value) * 100
                          : alert.config.threshold_value
                        : alert.config.threshold_value}
                      {kpiInfo.unit} during{" "}
                      {alert.config.duration_value?.toLowerCase()}.
                    </Text>
                    <Text>
                      <strong>Notify:</strong>{" "}
                      {alert.config.notify ? "Yes" : "No"}
                    </Text>
                  </Paper>
                );
              })}
            </Stack>
          </Paper>
        </Stack>
      </Group>
    </Container>
  );
};

export default ProjectKPIAlerts;
