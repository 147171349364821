import { useParams, useSearchParams } from "react-router-dom";

import CustomCard from "@/components/CustomCard";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { useGetDevices, useGetTimeSeries } from "@/hooks/api";
import { Select, Stack, Title } from "@mantine/core";

const ProjectEquipmentAnalysisTracker = () => {
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const deviceId = searchParams.get("deviceId");

  const deviceData = useGetDevices({
    pathParams: {
      projectId: projectId || "-1",
    },
    queryParams: {
      device_type_ids: [2, 6, 10],
    },
  });

  const hasPVPCSGroups = deviceData.data?.some((d) => d.device_type_id === 10);

  let device_ids: number[];
  if (hasPVPCSGroups) {
    console.log(hasPVPCSGroups);

    const PVPCSids = deviceData.data
      ?.filter((d) => d.parent_device_id === Number(deviceId))
      .map((d) => d.device_id);

    const PVPCSTrackerGroupids = deviceData.data
      ?.filter((d) => PVPCSids?.includes(d.parent_device_id ?? -1))
      .map((d) => d.device_id);

    device_ids = PVPCSTrackerGroupids || [];
  } else {
    device_ids = [Number(deviceId) || -1];
  }

  const trackerData = useGetTimeSeries({
    pathParams: {
      projectId: projectId || "-1",
    },
    queryParams: {
      device_ids: device_ids,
      sensor_type_name_shorts: [
        "tracker_position",
        "tracker_setpoint",
        "tracker_stow_command",
      ],
    },
    queryOptions: {
      enabled: !!deviceId,
      refetchOnWindowFocus: false,
    },
  });

  return (
    <Stack p="md">
      <Title order={1}>Tracker Equipment Analysis</Title>
      <Select
        value={deviceId || null}
        withCheckIcon={false}
        placeholder="Select a block"
        data={deviceData.data
          ?.filter((d) => d.device_type_id === 6)
          .map((d) => ({
            value: String(d.device_id),
            label: "Block " + d.name_long || "no name",
          }))}
        onChange={(value) => {
          setSearchParams({ deviceId: value || "1" });
        }}
        disabled={deviceData.isLoading}
      />
      <CustomCard title="Tracker Position" style={{ height: "50vh" }}>
        <PlotlyPlot
          data={trackerData.data
            ?.filter((d) => d.sensor_type_name === "tracker_position")
            .map((d) => ({
              x: d.x,
              y: d.y,
              name: `${d.tag_name_long}`,
              hoverlabel: {
                namelength: -1,
              },
            }))}
          isLoading={trackerData.isLoading}
          error={trackerData.error}
        />
      </CustomCard>
      <CustomCard title="Tracker Setpoint" style={{ height: "50vh" }}>
        <PlotlyPlot
          data={trackerData.data
            ?.filter((d) => d.sensor_type_name === "tracker_setpoint")
            .map((d) => ({
              x: d.x,
              y: d.y,
              name: `${d.tag_name_long}`,
              hoverlabel: {
                namelength: -1,
              },
            }))}
          isLoading={trackerData.isLoading}
          error={trackerData.error}
        />
      </CustomCard>
      <CustomCard title="Tracker Stow Command" style={{ height: "50vh" }}>
        <PlotlyPlot
          data={trackerData.data
            ?.filter((d) => d.sensor_type_name === "tracker_stow_command")
            .map((d) => ({
              x: d.x,
              y: d.y,
              name: `${d.tag_name_long}`,
              hoverlabel: {
                namelength: -1,
              },
            }))}
          isLoading={trackerData.isLoading}
          error={trackerData.error}
        />
      </CustomCard>
    </Stack>
  );
};

export default ProjectEquipmentAnalysisTracker;
