import CustomCard from "@/components/CustomCard";
import { Stack, Title, useMantineTheme } from "@mantine/core";
import { useParams } from "react-router-dom";

import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { traceColors } from "@/components/plots/PlotlyPlotUtils";
import { useGetTimeSeries } from "@/hooks/api";

const MetStationPerformance = () => {
  const { projectId } = useParams();
  const theme = useMantineTheme();

  const { data, isLoading, error } = useGetTimeSeries({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      sensor_type_name_shorts: [
        "met_station_poa",
        "met_station_ghi",
        "met_station_ambient_temperature",
        "met_station_wind_speed",
      ],
    },
  });

  const uniqueDeviceNames = Array.from(
    new Set(data?.map((d) => d.device_name_long))
  );

  const traceColorsTheme = traceColors(theme);

  // Map each unique device name to a color
  const colorMap = uniqueDeviceNames.reduce((acc, cur, idx) => {
    acc[cur] = traceColorsTheme[idx % traceColorsTheme.length];
    return acc;
  }, {} as { [key: string]: string });

  return (
    <Stack h="100%" p="md">
      <Title order={1}>Met Station Performance</Title>
      <CustomCard title="Met Stations" style={{ height: "100%" }}>
        <PlotlyPlot
          data={data
            ?.filter((d) => d.sensor_type_name === "met_station_poa")
            .map((d) => ({
              x: d.x,
              y: d.y,
              name: "Block " + d.device_name_long + " " + d.name,
              hoverlabel: {
                namelength: -1,
              },
              line: { color: colorMap[d.device_name_long] },
            }))
            .concat(
              data
                ?.filter((d) => d.sensor_type_name === "met_station_ghi")
                .map((d) => ({
                  x: d.x,
                  y: d.y,
                  name: "Block " + d.device_name_long + " " + d.name,
                  hoverlabel: {
                    namelength: -1,
                  },
                  line: { color: colorMap[d.device_name_long] },
                }))
            )
            .concat(
              data
                ?.filter(
                  (d) =>
                    d.sensor_type_name === "met_station_ambient_temperature"
                )
                .map((d) => ({
                  x: d.x,
                  y: d.y,
                  yaxis: "y2",
                  name: "Block " + d.device_name_long + " " + d.name,
                  hoverlabel: {
                    namelength: -1,
                  },
                  line: { color: colorMap[d.device_name_long] },
                }))
            )
            .concat(
              data
                ?.filter((d) => d.sensor_type_name === "met_station_wind_speed")
                .map((d) => ({
                  x: d.x,
                  y: d.y,
                  yaxis: "y3",
                  name: "Block " + d.device_name_long + " " + d.name,
                  hoverlabel: {
                    namelength: -1,
                  },
                  line: { color: colorMap[d.device_name_long] },
                }))
            )}
          layout={{
            grid: { rows: 3, columns: 1 },
            yaxis: { title: "Irradiance (W/m<sup>2</sup>)" },
            yaxis2: {
              title: "Temperature (°C)",
            },
            yaxis3: {
              title: "Wind Speed (m/s)",
            },
          }}
          isLoading={isLoading}
          error={error}
        />
      </CustomCard>
    </Stack>
  );
};

export default MetStationPerformance;
