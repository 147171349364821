import { useGetDevice, useGetDevices, useGetProject } from "@/hooks/api";
import { findBoundingBox, layerLabel } from "@/utils/GIS";
import {
  LoadingOverlay,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { IconDatabaseOff, IconFlag } from "@tabler/icons-react";
import { FeatureCollection } from "geojson";
import Map, { Layer, Marker, Source } from "react-map-gl";
import { useParams } from "react-router-dom";

interface EventGISCardProps {
  deviceId: string;
}

const useProjectId = () => useParams().projectId || "-1";

const EventGISCard = (deviceId: EventGISCardProps) => {
  const projectId = useProjectId();
  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme("dark");

  const {
    data: deviceData,
    isLoading: isDeviceLoading,
    error: deviceError,
  } = useGetDevice({
    pathParams: { projectId, deviceId: deviceId.deviceId },
  });

  const {
    data: projectData,
    isLoading: isProjectLoading,
    error: projectError,
  } = useGetProject({
    pathParams: { projectId },
  });

  const devices = useGetDevices({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      device_type_ids: [6],
    },
  });

  const { data: parentDeviceData } = useGetDevice({
    pathParams: {
      projectId,
      deviceId: deviceData?.parent_device_id?.toString() || "-1",
    },
  });

  let coordinates: [number, number] = [0, 0];
  if (!projectData?.has_pv_pcs_layout) {
    coordinates = [
      parentDeviceData?.point?.coordinates[0] || 0,
      parentDeviceData?.point?.coordinates[1] || 0,
    ];
  } else {
    coordinates = [
      deviceData?.point?.coordinates[0] || 0,
      deviceData?.point?.coordinates[1] || 0,
    ];
  }
  const isLoading = isDeviceLoading || devices.isLoading || isProjectLoading;
  const isError = deviceError || projectError || devices.isError;
  if (isLoading) {
    return <LoadingOverlay visible={true} />;
  }
  if (isError) {
    return <IconDatabaseOff size={48} strokeWidth={1} />;
  }

  const filteredData = {
    type: "FeatureCollection",
    features: devices.data?.map((device) => {
      return {
        type: "Feature",
        geometry: device.polygon,
      };
    }),
  } as FeatureCollection;

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      <Map
        initialViewState={{
          bounds: findBoundingBox(filteredData),
          fitBoundsOptions: {
            padding: 35,
          },
        }}
        style={{
          borderBottomLeftRadius: "inherit",
          borderBottomRightRadius: "inherit",
        }}
        mapStyle={
          computedColorScheme === "dark"
            ? "mapbox://styles/mapbox/dark-v9"
            : "mapbox://styles/mapbox/light-v9"
        }
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
      >
        <Source id="data" type="geojson" data={filteredData}>
          <Layer
            id="data"
            type="fill"
            paint={{
              "fill-color": theme.colors.gray[5], // Custom fill color
              "fill-opacity": 0.7,
            }}
          />
          {<Layer {...layerLabel({ textField: "name" })} />}
        </Source>
        <Marker key="Key!" longitude={coordinates[0]} latitude={coordinates[1]}>
          <IconFlag color={"#ff0000"} fill={"#ff0000"}></IconFlag>
        </Marker>
      </Map>
    </div>
  );
};

export default EventGISCard;
