import { ActionIcon, Badge, Card, Group, HoverCard, Text } from "@mantine/core";
import { useFullscreen } from "@mantine/hooks";
import {
  IconArrowsMaximize,
  IconArrowsMinimize,
  IconInfoCircle,
} from "@tabler/icons-react";

const CardTitle = ({
  beta,
  title,
  info,
  quality,
  toggle,
  fullscreen,
  showDownload,
  children,
}: {
  beta?: boolean;
  title?: string;
  info?: string;
  quality?: React.ReactNode;
  toggle: () => void;
  fullscreen: boolean;
  showDownload?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <Group justify="apart">
      <Group gap={3}>
        {beta && <Badge variant="filled">Beta</Badge>}
        {title && <Text fw={500}>{title}</Text>}
        {info && (
          <HoverCard shadow="md">
            <HoverCard.Target>
              <IconInfoCircle />
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm">{info}</Text>
            </HoverCard.Dropdown>
          </HoverCard>
        )}
        {quality}
      </Group>
      <Group style={{ flex: 1 }} />
      {children}
      <Group gap="xs">
        {showDownload && (
          <ActionIcon>{/* <IconDownload onClick={download} /> */}</ActionIcon>
        )}
        <ActionIcon onClick={toggle} variant="default">
          {fullscreen ? <IconArrowsMinimize /> : <IconArrowsMaximize />}
        </ActionIcon>
      </Group>
    </Group>
  );
};

const CustomCard = ({
  beta,
  title,
  info,
  quality,
  showDownload,
  fill,
  style,
  header = true,
  headerChildren,
  children,
}: {
  beta?: boolean;
  title?: string;
  info?: string;
  quality?: React.ReactNode;
  showDownload?: boolean;
  fill?: boolean;
  style?: React.CSSProperties;
  header?: boolean;
  headerChildren?: React.ReactNode;
  children: React.ReactNode;
}) => {
  const { ref, toggle, fullscreen } = useFullscreen();

  let padding: string | number = "md";

  if (fill) {
    padding = 0;
  }

  return (
    <Card
      withBorder
      radius="md"
      style={{
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
      ref={ref}
    >
      {header && (
        <Card.Section withBorder inheritPadding py={5}>
          <CardTitle
            beta={beta}
            title={title}
            info={info}
            quality={quality}
            toggle={toggle}
            fullscreen={fullscreen}
            showDownload={showDownload}
            children={headerChildren}
          />
        </Card.Section>
      )}
      <Card.Section p={padding} style={{ height: "100%" }}>
        {children}
      </Card.Section>
    </Card>
  );
};

export default CustomCard;
