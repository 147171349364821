import { useGetPVDCCombinerFuseHealthReport, useGetReport } from "@/hooks/api";
import { NOTIFICATION_ERROR_MESSAGE } from "@/utils";
import { Accordion, ActionIcon, Group, Stack, Title } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { notifications } from "@mantine/notifications";
import { IconDownload } from "@tabler/icons-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

const Page = () => {
  const reports = [
    {
      name: "Performance Summary",
      component: <PerformanceSummary />,
    },
    {
      name: "PV DC Combiner Fuse Health Report",
      component: <PVDCCombinerFuseHealthReport />,
    },
  ];

  return (
    <Stack p="md">
      <Title order={1}>Reports</Title>
      <Accordion variant="separated" chevronPosition="left">
        {reports.map((report, index) => (
          <Accordion.Item key={index} value={report.name}>
            <Accordion.Control>{report.name}</Accordion.Control>
            <Accordion.Panel>{report.component}</Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Stack>
  );
};

const PerformanceSummary = () => {
  const { projectId } = useParams();

  const { isLoading, isFetching, refetch } = useGetReport({
    pathParams: {
      projectId: projectId || "-1",
    },
    queryOptions: {
      enabled: false,
    },
  });

  const handleClick = async () => {
    try {
      const { data, error } = await refetch(); // Assume `data` is of type `Report`

      if (data) {
        // Decode the base64 PDF data to a binary format
        const pdfData = atob(data.data_pdf); // Decode base64 to binary string

        // Convert binary string to an array of bytes
        const pdfBytes = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          pdfBytes[i] = pdfData.charCodeAt(i);
        }

        // Create a Blob from the PDF bytes
        const file = new Blob([pdfBytes], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        // Use the provided filename from the Report data
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", data.filename); // Use the `filename` from the `Report`
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (error) {
        notifications.show({
          title: "Not able to download report",
          message: NOTIFICATION_ERROR_MESSAGE,
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error fetching the report:", error);
    }
  };

  return (
    <Group>
      <div>Performance Summary</div>
      <ActionIcon onClick={handleClick} loading={isLoading || isFetching}>
        <IconDownload />
      </ActionIcon>
    </Group>
  );
};

const PVDCCombinerFuseHealthReport = () => {
  const [date, setDate] = useState<Date | null>(null);

  const data = useGetPVDCCombinerFuseHealthReport({
    pathParams: { projectId: "b102379c-eadb-4cc4-808b-a3d4b3f3ea5a" },
    queryParams: {
      start: date?.toISOString() || "",
    },
    queryOptions: {
      enabled: false,
    },
  });

  const downloadFile = (blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDownload = async () => {
    const blob = await data.refetch();
    if (blob.data) {
      downloadFile(blob.data, "example.xlsx");
    }
  };

  return (
    <Group>
      <DateInput value={date} onChange={setDate} />
      <ActionIcon disabled={date === null} onClick={handleDownload}>
        <IconDownload />
      </ActionIcon>
    </Group>
  );
};

export default Page;
