import CustomCard from "@/components/CustomCard";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import {
  useGetDevices,
  useGetEquipmentAnalysisPCS,
  useGetHeatmap,
} from "@/hooks/api";
import {
  Group,
  RingProgress,
  Skeleton,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useParams } from "react-router-dom";

const colorFromPercent = (numerator: number, denominator: number) => {
  const percent = (numerator / denominator) * 100;
  if (percent >= 90) {
    return "green";
  } else if (percent >= 75) {
    return "yellow";
  } else {
    return "red";
  }
};

const PCSHeatmap = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const { data, isLoading, error } = useGetHeatmap({
    pathParams: {
      projectId: projectId || "-1",
      sensorTypeName: "pv_pcs_ac_power",
    },
    queryParams: {
      fillna_zero: false,
    },
  });

  return (
    <PlotlyPlot
      data={[
        {
          z: data?.z,
          x: data?.x,
          y: data?.y,
          type: "heatmap",
        },
      ]}
      colorscale={"primary"}
      isLoading={isLoading}
      error={error}
    />
  );
};

interface RingProgressCardProps {
  title: string;
  subtitle: string;
  value: number | null;
  total: number | null;
  color?: string;
  isLoading: boolean;
  size?: number;
  skeletonHeight?: number;
  skeletonMargin?: number;
}

const RingProgressCard: React.FC<RingProgressCardProps> = ({
  title,
  subtitle,
  value,
  total,
  color = "grey",
  isLoading,
  size = 150,
  skeletonHeight = 111,
  skeletonMargin = 19.5,
}) => {
  return (
    <Stack align="center" gap={0}>
      <Text>{title}</Text>
      <Text size="sm">{subtitle}</Text>
      {isLoading ? (
        <Skeleton height={skeletonHeight} circle m={skeletonMargin} />
      ) : (
        <RingProgress
          size={size}
          label={
            <Text size="sm" ta="center">
              {value !== null && total !== null
                ? `${value}/${total}`
                : "No Data"}
            </Text>
          }
          sections={[
            {
              value:
                value !== null && total !== null ? (value / total) * 100 : 0,
              color,
            },
          ]}
        />
      )}
    </Stack>
  );
};

const PCSEquipmentAnalysis = () => {
  const { projectId } = useParams();

  const devices = useGetDevices({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { device_type_ids: [3] },
    queryOptions: { enabled: !!projectId },
  });

  const data = useGetEquipmentAnalysisPCS({
    pathParams: { projectId: projectId || "-1" },
    queryOptions: { enabled: !!projectId },
  });

  if (devices.isLoading) {
    return <PageLoader />;
  }

  let hasPCSModules = false;
  if (devices.data && devices.data.length > 0) {
    hasPCSModules = true;
  }

  return (
    <Stack p="md">
      <Title order={1}>PV PCS Equipment Analysis</Title>
      <Group w="100%" justify="space-evenly" align="flex-end">
        <RingProgressCard
          title="Power Output"
          subtitle="Out of nameplate capacity"
          value={data.data?.total_power_output.value ?? null}
          total={data.data?.total_power_output.total_nameplate ?? null}
          isLoading={data.isLoading}
          color="grey"
        />
        <RingProgressCard
          title="Blocks"
          subtitle="Generating Power"
          value={data.data?.generating_power_block.value ?? null}
          total={data.data?.generating_power_block.total ?? null}
          isLoading={data.isLoading}
          color={
            data.data
              ? colorFromPercent(
                  data.data.generating_power_block.value,
                  data.data.generating_power_block.total
                )
              : "grey"
          }
        />
        <RingProgressCard
          title="PCSs"
          subtitle="Generating Power"
          value={data.data?.generating_power_pcs.value ?? null}
          total={data.data?.generating_power_pcs.total ?? null}
          isLoading={data.isLoading}
          color={
            data.data
              ? colorFromPercent(
                  data.data.generating_power_pcs.value,
                  data.data.generating_power_pcs.total
                )
              : "grey"
          }
        />
        {hasPCSModules && (
          <RingProgressCard
            title="PCS Modules"
            subtitle="Generating Power"
            value={data.data?.generating_power_pcs_module.value ?? null}
            total={data.data?.generating_power_pcs_module.total ?? null}
            isLoading={data.isLoading}
            color={
              data.data
                ? colorFromPercent(
                    data.data.generating_power_pcs_module.value,
                    data.data.generating_power_pcs_module.total
                  )
                : "grey"
            }
          />
        )}
      </Group>
      <CustomCard title="Block Output Distribution" style={{ height: "250px" }}>
        <PlotlyPlot
          data={
            data.data && [
              {
                x: data.data.block_power_distribution.x,
                y: data.data.block_power_distribution.y,
                customdata: data.data.block_power_distribution.customdata,
                type: "bar",
              },
            ]
          }
          layout={
            data.data && {
              xaxis: { type: "category", title: { text: "Block" } },
              yaxis: {
                range: [
                  0,
                  data.data.block_power_distribution.yaxis_range_max * 1.05,
                ],
                title: { text: "Power (MW)" },
              },
            }
          }
          isLoading={data.isLoading}
          error={data.error}
        />
      </CustomCard>
      <CustomCard title="PCS Output Distribution" style={{ height: "250px" }}>
        <PlotlyPlot
          data={
            data.data && [
              {
                x: data.data.pcs_power_distribution.x,
                y: data.data.pcs_power_distribution.y,
                customdata: data.data.pcs_power_distribution.customdata,
                type: "bar",
              },
            ]
          }
          layout={
            data.data && {
              xaxis: { type: "category", title: { text: "PCS" } },
              yaxis: {
                range: [
                  0,
                  data.data.pcs_power_distribution.yaxis_range_max * 1.05,
                ],
                title: { text: "Power (MW)" },
              },
            }
          }
          isLoading={data.isLoading}
          error={data.error}
        />
      </CustomCard>
      {hasPCSModules && (
        <CustomCard
          title="PCS Module Output Distribution"
          style={{ height: "250px" }}
        >
          <PlotlyPlot
            data={
              data.data && [
                {
                  x: data.data.pcs_module_power_distribution.x,
                  y: data.data.pcs_module_power_distribution.y,
                  customdata:
                    data.data.pcs_module_power_distribution.customdata,
                  type: "bar",
                },
              ]
            }
            layout={
              data.data && {
                xaxis: { type: "category", title: { text: "PCS Module" } },
                yaxis: {
                  range: [
                    0,
                    data.data.pcs_module_power_distribution.yaxis_range_max *
                      1.05,
                  ],
                  title: { text: "Power (MW)" },
                },
              }
            }
            isLoading={data.isLoading}
            error={data.error}
          />
        </CustomCard>
      )}
      <CustomCard title="PCS Power Heatmap" style={{ height: "500px" }}>
        <PCSHeatmap />
      </CustomCard>
    </Stack>
  );
};

export default PCSEquipmentAnalysis;
