import CustomCard from "@/components/CustomCard";
import { useValidateDateRange } from "@/components/datepicker/utils";
import { AdvancedDatePicker } from "@/components/GIS";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { traceColors } from "@/components/plots/PlotlyPlotUtils";
import {
  useGetDevices,
  useGetProject,
  useGetTags,
  useGetTimeSeries,
} from "@/hooks/api";
import {
  ActionIcon,
  Button,
  Group,
  Indicator,
  Select,
  Stack,
  Table,
  Title,
  useMantineTheme,
} from "@mantine/core";
import {
  IconArrowBackUp,
  IconArrowDown,
  IconArrowUp,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);

type combinerData = {
  combiner_device_id: number;
  combiner_dc_capacity: number;
  combiner_name_long: string;
  tag_name_scada: string;
};

const MAX_DAYS = 7;

const Page = () => {
  const theme = useMantineTheme();
  const { projectId } = useParams();
  const [indexArray, setIndexArray] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const blockDeviceId = searchParams.get("deviceId");

  // Fetch project data
  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
    queryOptions: { enabled: false },
  });

  const { start, end } = useValidateDateRange({
    maxDays: MAX_DAYS,
  });

  let startRequest, endRequest;
  if (project.data) {
    startRequest =
      start && start.tz(project.data.time_zone, true).toISOString();
    endRequest = end && end.tz(project.data.time_zone, true).toISOString();
  }

  const devices = useGetDevices({
    pathParams: {
      projectId: projectId || "-1",
    },
    queryParams: {
      device_type_ids: [2, 6, 9],
    },
  });

  const combinerTags = useGetTags({
    pathParams: {
      projectId: projectId || "-1",
    },
    queryParams: {
      sensor_type_ids: [27],
      deep: true,
    },
  });

  const pcsDeviceIds = devices.data
    ?.filter((d) => d.parent_device_id === Number(blockDeviceId))
    .map((d) => d.device_id);

  const combinerDeviceIds = devices.data
    ?.filter((d) => pcsDeviceIds?.includes(d.parent_device_id ?? -1))
    .map((d) => d.device_id);

  const combinerData = useGetTimeSeries({
    pathParams: {
      projectId: projectId || "-1",
    },
    queryParams: {
      device_ids: combinerDeviceIds,
      sensor_type_name_shorts: ["pv_dc_combiner_current"],
      start: startRequest ?? undefined,
      end: endRequest ?? undefined,
    },
    queryOptions: {
      enabled:
        combinerDeviceIds &&
        combinerDeviceIds.length > 0 &&
        !!project.data &&
        !!start &&
        !!end,
    },
  });

  if (devices.isLoading || combinerTags.isLoading) {
    return <PageLoader />;
  }

  if (!devices.data || !combinerTags.data) {
    return <div>No data</div>;
  }

  let data = combinerTags.data
    ?.filter((d) => pcsDeviceIds?.includes(d.device.parent_device_id ?? -1))
    .map((d) => ({
      combiner_device_id: d.device.device_id,
      combiner_dc_capacity: d.device.capacity_dc ?? 0,
      combiner_name_long: d.device.name_long ?? "",
      tag_name_scada: d.name_scada,
    }))
    .sort((a, b) => a.combiner_name_long.localeCompare(b.combiner_name_long));

  if (indexArray.length > 0) {
    // set tag_name_scada based on indexArray
    const tag_name_scada_array = indexArray.map((i) => data[i].tag_name_scada);
    data = data.map((d, i) => ({
      ...d,
      tag_name_scada: tag_name_scada_array[i],
    }));
  }

  const tagNameToCapacityDC: {
    [key: string]: number;
  } = data.reduce(
    (acc, d) => ({
      ...acc,
      [d.tag_name_scada]: d.combiner_dc_capacity,
    }),
    {}
  );

  // Get unique array of data.combiner_dc_capacity
  const capacityDCs = Array.from(
    new Set(data.map((d) => d.combiner_dc_capacity).sort((a, b) => a - b))
  );

  const colors = traceColors(theme);

  // Create an object mapping capacityDCs to colors
  const capacityDCsToColors: {
    [key: number]: string;
  } = capacityDCs.reduce(
    (acc, d, i) => ({
      ...acc,
      [d]: colors[i],
    }),
    {}
  );

  const blockDevices = devices.data?.filter((d) => d.device_type_id === 6);

  const handleMove = (index: number, direction: "up" | "down") => {
    let indexArrayLocal;
    if (indexArray.length === 0) {
      // Create an array of from 0 to data.length
      indexArrayLocal = Array.from({ length: data.length }, (_, i) => i);
    } else {
      indexArrayLocal = indexArray;
    }

    const indexValue = indexArrayLocal[index];
    const adjacentIndex = direction === "up" ? index - 1 : index + 1;
    const adjacentValue = indexArrayLocal[adjacentIndex];

    const newIndexArray = [...indexArrayLocal];
    newIndexArray[index] = adjacentValue;
    newIndexArray[adjacentIndex] = indexValue;

    setIndexArray(newIndexArray);
  };

  const handleCopy = () => {
    const tagNames = data
      .map((d) => {
        const row = [
          `C${d.combiner_name_long}`,
          d.combiner_dc_capacity,
          d.tag_name_scada,
        ];
        return row.join("\t");
      })
      .join("\n");

    navigator.clipboard.writeText(tagNames);
  };

  return (
    <Stack p="md">
      <Title order={1}>Combiner Equipment Analysis</Title>
      <Group>
        <Select
          value={blockDeviceId}
          withCheckIcon={false}
          searchable
          placeholder="Select a block"
          data={blockDevices.map((d) => ({
            value: String(d.device_id),
            label: "Block " + d.name_long || "no name",
            disabled: blockDeviceId === String(d.device_id),
          }))}
          onChange={(value) => {
            setIndexArray([]);
            if (value) {
              searchParams.set("deviceId", value);
              setSearchParams(searchParams);
            }
          }}
        />
        <AdvancedDatePicker
          includeClearButton={false}
          defaultRange="today"
          includeTodayInDateRange
          limits={{
            day: 7,
            week: 1,
            month: 0,
            quarter: 0,
            year: 0,
          }}
          disableQuickActions={true}
          maxDays={MAX_DAYS}
        />
        <Link to={`/projects/${projectId}/equipment-analysis/pv-dc-combiner`}>
          <Button variant="light" rightSection={<IconArrowBackUp size={14} />}>
            Back to Project
          </Button>
        </Link>
      </Group>
      <CustomCard
        title="Combiners"
        headerChildren={
          <Button
            variant="default"
            size="compact-xs"
            onClick={handleCopy}
            style={{ alignSelf: "flex-end", WebkitAlignSelf: "auto" }}
          >
            Copy to Clipboard
          </Button>
        }
      >
        {data !== undefined && data.length > 0 ? (
          <Table striped highlightOnHover verticalSpacing={2}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Power (kW)</Table.Th>
                <Table.Th>SCADA Tag Name</Table.Th>
                <Table.Th>Actions</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.map((d, index) => (
                <Table.Tr key={d.combiner_device_id}>
                  <Table.Td>{d.combiner_name_long}</Table.Td>
                  <Table.Td>
                    <Group>
                      <Indicator
                        color={capacityDCsToColors[d.combiner_dc_capacity]}
                        offset={0}
                      />
                      {d.combiner_dc_capacity}
                    </Group>
                  </Table.Td>
                  <Table.Td>{d.tag_name_scada}</Table.Td>
                  <Table.Td>
                    <BumpTo index={index} data={data} handleMove={handleMove} />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        ) : (
          <Group justify="center">Select a block to see combiners</Group>
        )}
      </CustomCard>

      <CustomCard title="Combiner Current" style={{ height: "50vh" }}>
        <PlotlyPlot
          data={combinerData.data
            ?.filter((d) => d.sensor_type_name === "pv_dc_combiner_current")
            .map((d) => ({
              x: d.x,
              y: d.y,
              name: `${d.device_name_long} - ${d.tag_name_scada}`,
              hoverlabel: {
                namelength: -1,
              },
              line: {
                color:
                  capacityDCsToColors[tagNameToCapacityDC[d.tag_name_scada]],
              },
            }))}
          isLoading={combinerData.isLoading}
          error={combinerData.error}
        />
      </CustomCard>
      <CustomCard
        title="Combiner Current per Capacity"
        style={{ height: "50vh" }}
      >
        <PlotlyPlot
          data={combinerData.data
            ?.filter((d) => d.sensor_type_name === "pv_dc_combiner_current")
            .map((d) => ({
              x: d.x,
              y: d.y.map((y) => y / tagNameToCapacityDC[d.tag_name_scada]),
              name: `${d.device_name_long} - ${d.tag_name_scada}`,
              hoverlabel: {
                namelength: -1,
              },
              line: {
                color:
                  capacityDCsToColors[tagNameToCapacityDC[d.tag_name_scada]],
              },
            }))}
          isLoading={combinerData.isLoading}
          error={combinerData.error}
        />
      </CustomCard>
    </Stack>
  );
};

const BumpTo = ({
  index,
  data,
  handleMove,
}: {
  index: number;
  data: combinerData[];
  handleMove: (currentId: number, direction: "up" | "down") => void;
}) => {
  return (
    <div>
      <ActionIcon
        size="xs"
        onClick={() => handleMove(index, "up")}
        disabled={index === 0}
        mr={5}
      >
        <IconArrowUp style={{ width: "70%", height: "70%" }} stroke={1.5} />
      </ActionIcon>

      <ActionIcon
        size="xs"
        onClick={() => handleMove(index, "down")}
        disabled={index === data.length - 1}
      >
        <IconArrowDown style={{ width: "70%", height: "70%" }} stroke={1.5} />
      </ActionIcon>
    </div>
  );
};

export default Page;
