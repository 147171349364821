import { MantineTheme } from "@mantine/core";

export const traceColors = (theme: MantineTheme) => {
  const colorsToOmit = [
    "dark", // #101113
    "gray", // #212529
    "pink", // #A61E4D
    "violet", // #5F3DC4
    "blue", // #1864AB
    "teal", // #087F5B
    "lime", // #5C940D
    "yellow", // #E67700
  ];

  let filteredColors = Object.entries(theme.colors)
    .filter(([key]) => !colorsToOmit.includes(key))
    .reduce<Record<string, typeof theme.colors.dark>>((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  filteredColors = {
    orange: filteredColors["orange"], // #D9480F
    red: filteredColors["red"], // #C92A2A
    indigo: filteredColors["indigo"], // #364FC7
    green: filteredColors["green"], // #2B8A3E
    grape: filteredColors["grape"], // #862E9C
    cyan: filteredColors["cyan"], // #0B7285
  };

  // Check if theme.primaryColor is in the colors object
  if (Object.keys(filteredColors).includes(theme.primaryColor)) {
    const primaryColor = theme.colors[theme.primaryColor];
    delete filteredColors[theme.primaryColor];
    filteredColors = {
      [theme.primaryColor]: primaryColor,
      ...filteredColors,
    };
  }

  const numColors = 3;
  const lastColors = Object.values(filteredColors).map((color) =>
    color.slice(-numColors)
  );
  const orderedColors = [];
  for (let i = 0; i < numColors; i++) {
    for (const set of lastColors) {
      orderedColors.push(set[i]);
    }
  }
  return orderedColors;
};
