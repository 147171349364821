import CustomCard from "@/components/CustomCard";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { useGetDevices, useGetEquipmentAnalysisCombiner } from "@/hooks/api";
import {
  Checkbox,
  Group,
  HoverCard,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Page = () => {
  const { projectId } = useParams();

  const [checked, setChecked] = useState(false);

  const data = useGetEquipmentAnalysisCombiner({
    pathParams: { projectId: projectId || "-1" },
    queryOptions: { enabled: !!projectId },
  });

  return (
    <Stack p="md" h="100%">
      <Title order={1}>Combiner Equipment Analysis</Title>
      <Group>
        <SelectBlock />
        <Info />
      </Group>
      <CustomCard
        title="Combiner Output Distribution"
        style={{ flex: 1 }}
        headerChildren={
          <Checkbox
            label="Normalize by Combiner Power"
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
          />
        }
      >
        <PlotlyPlot
          data={
            data.data && [
              {
                x: data.data.x,
                y: checked ? data.data.y_norm : data.data.y,
                type: "bar",
              },
            ]
          }
          layout={{
            yaxis: {
              title: checked ? "Current/Power (A/kW)" : "Current (A)",
            },
          }}
          isLoading={data.isLoading}
          error={data.error}
        />
      </CustomCard>
    </Stack>
  );
};

const SelectBlock = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const data = useGetDevices({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { device_type_ids: [6] },
  });

  return (
    <Select
      placeholder="Select a block"
      data={data.data?.map((d) => ({
        value: String(d.device_id),
        label: "Block " + (d.name_long ?? "UNKNOWN"),
      }))}
      onChange={(value) => {
        navigate(
          `/projects/${projectId}/equipment-analysis/pv-dc-combiner/block?deviceId=${value}`
        );
      }}
      disabled={data.isLoading}
      comboboxProps={{ zIndex: 500 }}
    />
  );
};

const Info = () => {
  return (
    <HoverCard width={500}>
      <HoverCard.Target>
        <IconInfoCircle />
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text size="sm">
          Select an option from the dropdown to see more details for that block.
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export default Page;
