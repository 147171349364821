import * as types from "@/hooks/types";
import { Box, ScrollArea, Text, Timeline } from "@mantine/core";
// import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const DeviceEventsTimeline = ({
  events,
  failureModes,
  projectId,
}: {
  events: types.Event[];
  failureModes: types.FailureMode[];
  projectId: string;
}) => {
  //   const [opened, { close, open }] = useDisclosure(false);
  events = [...events].sort(
    (a, b) => dayjs(b.time_start).unix() - dayjs(a.time_start).unix()
  );
  let timelineLength = 2;
  if (events[0].time_end) {
    timelineLength = 1;
  }
  return (
    <ScrollArea scrollbars="y" p="xs">
      <ScrollArea.Autosize mah={650} mih={650}>
        <Box>
          {/* <EditRootModal opened={opened} onClose={close} /> */}
          <Timeline active={events.length - timelineLength} reverseActive>
            {events.map((event, index) => (
              <Timeline.Item key={index}>
                <Link
                  to={`/projects/${projectId}/events/event/?eventId=${event.event_id}`}
                  style={{ color: "inherit" }}
                >
                  <Text>
                    {event.time_end
                      ? `${dayjs(event.time_start).format(
                          "MM/DD/YYYY"
                        )} - ${dayjs(event.time_end).format("MM/DD/YYYY")}`
                      : dayjs(event.time_start).format("MM/DD/YYYY")}
                  </Text>
                </Link>
                <Text size="sm">
                  Root cause:{" "}
                  {failureModes.find(
                    (fm) => fm.failure_mode_id === event.failure_mode_id
                  )?.name_long || "Unknown"}
                </Text>
              </Timeline.Item>
            ))}
          </Timeline>
        </Box>
      </ScrollArea.Autosize>
    </ScrollArea>
  );
};

export default DeviceEventsTimeline;
