import CustomCard from "@/components/CustomCard";
import DeviceSunburst from "@/components/plots/DeviceSunburst";
import LossWaterfall from "@/components/plots/LossWaterfall";
import POIMeter from "@/components/plots/POIMeter";
import PowerPlantController from "@/components/plots/PowerPlantController";
import {
  ActionIcon,
  Group,
  Popover,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconSettings } from "@tabler/icons-react";
import { PlotType } from "plotly.js";
import { useState } from "react";

const SystemPerformance = () => {
  const [showLevel, setShowLevel] = useState("device_type");
  const [sunburstDepth, setSunburstDepth] = useState<string>("3");
  const [sunburstStyle, setSunburstStyle] = useState<PlotType>("sunburst");
  return (
    <Stack p="sm" h="100%">
      <Title order={1}>System Performance</Title>
      <Group flex={1} w="100%">
        <CustomCard title="POI Meter" style={{ height: "100%", flex: 1 }}>
          <POIMeter />
        </CustomCard>
        <CustomCard
          title="System Device Health"
          style={{ height: "100%", flex: 1 }}
          headerChildren={
            <Popover>
              <Popover.Target>
                <ActionIcon variant="default">
                  <IconSettings />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Select
                  data={["2", "3", "4"]}
                  label={"Plot Depth"}
                  value={sunburstDepth}
                  onChange={(value) => setSunburstDepth(value ?? "3")}
                />
                <Text>Plot Style:</Text>
                <SegmentedControl
                  data={[
                    { label: "Circular", value: "sunburst" },
                    { label: "Rectangular", value: "icicle" },
                  ]}
                  value={sunburstStyle}
                  onChange={(value) => setSunburstStyle(value as PlotType)}
                />
              </Popover.Dropdown>
            </Popover>
          }
        >
          <DeviceSunburst
            depth={parseInt(sunburstDepth)}
            style={sunburstStyle}
          />
        </CustomCard>
      </Group>

      <Group flex={1} w="100%">
        <CustomCard
          title="Power Plant Controller"
          style={{ height: "100%", flex: 1 }}
        >
          <PowerPlantController />
        </CustomCard>
        <CustomCard
          headerChildren={
            <SegmentedControl
              data={[
                { label: "Component", value: "device_type" },
                { label: "Root Cause", value: "failure_mode_id" },
              ]}
              value={showLevel}
              onChange={setShowLevel}
            />
          }
          title="Loss Waterfall"
          info="Unknown refers to lost energy that is associated with a loss event whose root cause is unknown, while Unaccounted Loss refers to lost energy that is not associated with any event."
          style={{ height: "100%", flex: 1 }}
        >
          <LossWaterfall level={showLevel} />
        </CustomCard>
      </Group>
    </Stack>
  );
};

export default SystemPerformance;
