import CustomCard from "@/components/CustomCard";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { useGetBrowsingTree, useGetTimeSeries } from "@/hooks/api";
import { DataTimeSeries } from "@/hooks/types";
import {
  Button,
  Checkbox,
  Group,
  HoverCard,
  Paper,
  RenderTreeNodePayload,
  ScrollArea,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  Tree,
  TreeNodeData,
  useTree,
} from "@mantine/core";
import { DatePickerInput, DatesProvider } from "@mantine/dates";
import { useDebouncedValue } from "@mantine/hooks";
import { IconChevronDown, IconTag } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

interface ValueProps {
  value: string;
  scada_name: string;
  device_name: string;
  unit: string;
}

const filterTree = (
  tree: TreeNodeData[],
  tagsToKeep: number[]
): TreeNodeData[] => {
  const filteredTree = tree
    .map((node) => {
      const shouldKeepNode =
        String(node.value).includes("device") ||
        tagsToKeep.includes(parseInt(node.value));

      const filteredChildren = node.children
        ? filterTree(node.children, tagsToKeep)
        : [];

      if (
        shouldKeepNode &&
        (filteredChildren.length > 0 || !String(node.value).includes("device"))
      ) {
        return { ...node, children: filteredChildren ?? [] };
      }
      return null;
    })
    .filter((node) => node !== null && node.children !== undefined);
  return filteredTree as TreeNodeData[];
};
const createReducedTree = (
  tree: TreeNodeData[],
  expandedState: Record<string, boolean>
): TreeNodeData[] => {
  return (
    tree
      .map((node) => {
        const isDeviceNode = String(node.value).includes("device");
        const isExpanded = expandedState[node.value];

        let filteredChildren: TreeNodeData[] = [];

        // If the node is a "device" node and not expanded, clear its children
        if (isDeviceNode && !isExpanded) {
          filteredChildren = [];
        } else if (node.children) {
          // Recursively process children if they exist
          filteredChildren = createReducedTree(node.children, expandedState);
        }

        // Return the node with its possibly modified children
        return {
          ...node,
          children: filteredChildren,
        };
      })
      // Sort nodes alphabetically by label
      .sort((a, b) => {
        const labelA = a.label?.toString().toLowerCase() || "";
        const labelB = b.label?.toString().toLowerCase() || "";
        return labelA.localeCompare(labelB);
      })
  );
};

function dataToCSV(data: DataTimeSeries[]) {
  const timestamps = data[0].x;
  const csvRows = [];

  const headers = ["Timestamp", ...data.map((sensor) => sensor.tag_name_scada)];
  csvRows.push(headers.join(","));

  timestamps.forEach((timestamp, index) => {
    const row = [timestamp];
    data.forEach((sensor) => {
      const value = sensor.y[index];
      row.push(String(value.toFixed(4)));
    });
    csvRows.push(row.join(","));
  });

  return csvRows.join("\n");
}
function handleDownload(data: DataTimeSeries[]) {
  const csvData = dataToCSV(data);
  download(csvData, "sensor-data.csv");
}

function download(csv: string, filename: string) {
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

const ProjectData = () => {
  const { projectId } = useParams();
  const [labelType, setLabelType] = useState("device_name");
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);

  const [filteredTreeData, setFilteredTreeData] = useState<TreeNodeData[]>([]);
  const [reducedTreeData, setReducedTreeData] = useState<TreeNodeData[]>([]);
  const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>(
    {}
  );

  const { data: rawTreeData, isLoading: treeDataLoading } = useGetBrowsingTree({
    pathParams: { projectId: projectId || "-1" },
  });

  const extractScadaPairs = (nodes: TreeNodeData[]): ValueProps[] => {
    let pairs: ValueProps[] = [];
    nodes.forEach((node) => {
      if (node.nodeProps?.scada_name) {
        pairs.push({
          value: node.value,
          scada_name: node.nodeProps.scada_name,
          device_name: node.nodeProps.device_name,
          unit: node.nodeProps.unit,
        });
      }
      if (node.children?.length) {
        pairs = pairs.concat(extractScadaPairs(node.children));
      }
    });
    return pairs;
  };
  const tree = useTree();
  const selectedItems = useMemo(() => {
    if (rawTreeData) {
      const scadaPairs = extractScadaPairs(rawTreeData);
      return tree.checkedState
        .map((value) => scadaPairs.find((pair) => pair.value === value))
        .filter((pair): pair is ValueProps => pair !== undefined);
    }
    return [];
  }, [rawTreeData, tree.checkedState]);

  const {
    data: timeSeriesData,
    isLoading: timeSeriesIsLoading,
    refetch,
  } = useGetTimeSeries({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      tag_ids: selectedItems.map((tag) => parseInt(tag.value)),
      start: dateRange[0] ? dayjs(dateRange[0]).toISOString() : undefined,
      end: dateRange[1]
        ? dayjs(dateRange[1]).add(1, "day").toISOString()
        : undefined,
    },
    queryOptions: { enabled: false },
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectAll = () => {
    if (rawTreeData) tree.checkNode(rawTreeData[0]?.value || "");
  };

  const renderTreeNode = useCallback(
    ({
      node,
      expanded,
      elementProps,
      tree,
      labelType,
    }: RenderTreeNodePayload & { labelType: string }) => {
      const checked = tree.isNodeChecked(node.value);
      const indeterminate = tree.isNodeIndeterminate(node.value);
      const displayLabel = node.nodeProps?.[labelType] || node.label;
      const otherType =
        labelType === "device_name" ? "scada_name" : "device_name";
      const hoverLabel = node.nodeProps?.[otherType] || node.label;

      return (
        <Group gap="xs" {...elementProps}>
          <Checkbox.Indicator
            checked={checked}
            indeterminate={indeterminate}
            size="sm"
            onClick={() =>
              checked
                ? tree.uncheckNode(node.value)
                : tree.checkNode(node.value)
            }
          />
          <Group
            gap={5}
            onClick={() => {
              const newExpandedNodes = {
                ...expandedNodes,
                [node.value]: !expandedNodes[node.value],
              };
              setExpandedNodes(newExpandedNodes);
              tree.toggleExpanded(node.value);
              setReducedTreeData(
                createReducedTree(filteredTreeData, newExpandedNodes)
              );
            }}
          >
            {node.nodeProps?.hoverable && <IconTag size={14} />}
            {node.nodeProps?.hoverable ? (
              <HoverCard position="right" width={280} shadow="md">
                <HoverCard.Target>
                  <Text size="14px">{displayLabel}</Text>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="14px">{hoverLabel}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            ) : (
              <Text size="14px">{node.label}</Text>
            )}
            {!node.nodeProps?.hoverable && (
              <IconChevronDown
                size={14}
                style={{
                  transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            )}
          </Group>
        </Group>
      );
    },
    [filteredTreeData, expandedNodes]
  );

  const filteredTags = useMemo(() => {
    try {
      const regex = new RegExp(debouncedSearchTerm, "i");
      return rawTreeData
        ? extractScadaPairs(rawTreeData).filter(
            (tag) => regex.test(tag.scada_name) || regex.test(tag.device_name)
          )
        : [];
    } catch {
      return rawTreeData ? extractScadaPairs(rawTreeData) : [];
    }
  }, [debouncedSearchTerm, rawTreeData]);

  const filteredTagInts = useMemo(
    () => filteredTags.map((tag) => parseInt(tag.value)),
    [filteredTags]
  );

  useEffect(() => {
    if (rawTreeData) {
      const filtered = filterTree(rawTreeData, filteredTagInts);
      setFilteredTreeData(filtered);
      setReducedTreeData(createReducedTree(filtered, expandedNodes));
    }
  }, [rawTreeData, filteredTagInts, expandedNodes]);

  const uniqueUnits = useMemo(() => {
    return Array.from(new Set(selectedItems.map((item) => item.unit)));
  }, [selectedItems]);

  const unitGroups = useMemo(() => {
    const colors = [
      "#1f77b4",
      "#ff7f0e",
      "#2ca02c",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf",
    ];

    return uniqueUnits.map((unit, index) => {
      const itemsWithUnit = selectedItems.filter((item) => item.unit === unit);
      return {
        unit,
        color: colors[index % colors.length],
        data: itemsWithUnit.map((item) => ({
          x:
            timeSeriesData?.find((d) => d.tag_name_scada === item.scada_name)
              ?.x || [],
          y:
            timeSeriesData?.find((d) => d.tag_name_scada === item.scada_name)
              ?.y || [],
          name: item.scada_name,
          hoverlabel: { namelength: -1 },
          yaxis: `y${index + 1}`,
          line: { color: colors[index % colors.length] },
        })),
      };
    });
  }, [selectedItems, timeSeriesData]);

  const layout = useMemo(() => {
    const baseLayout = {
      xaxis: { domain: [0.05, 0.95] },
      yaxis: {
        title: uniqueUnits[0],
        linecolor: unitGroups[0]?.color,
        showgrid: false,
        tickfont: { color: unitGroups[0]?.color },
        titlefont: { color: unitGroups[0]?.color },
      },
    };

    return unitGroups.length > 1
      ? {
          ...baseLayout,
          ...Object.fromEntries(
            unitGroups.slice(1).map((group, index) => [
              `yaxis${index + 2}`,
              {
                title: group.unit,
                overlaying: "y",
                side: index % 2 === 0 ? "right" : "left",
                autoshift: true,
                anchor: "free",
                showgrid: false,
                tickfont: { color: group.color },
                titlefont: { color: group.color },
              },
            ])
          ),
        }
      : baseLayout;
  }, [unitGroups, uniqueUnits]);

  // const handleExpandAll = useCallback(() => {
  //   const allExpanded = filteredTreeData.reduce((acc, node) => {
  //     acc[node.value] = true;
  //     return acc;
  //   }, {} as Record<string, boolean>);
  //   setExpandedNodes(allExpanded);
  //   tree.expandAllNodes();
  //   setReducedTreeData(createReducedTree(filteredTreeData, allExpanded));
  // }, [filteredTreeData, tree]);

  const handleCollapseAll = useCallback(() => {
    setExpandedNodes({});
    tree.collapseAllNodes();
    setReducedTreeData(createReducedTree(filteredTreeData, {}));
  }, [filteredTreeData, tree]);

  if (treeDataLoading || !rawTreeData) {
    return <PageLoader />;
  }

  return (
    <Group h="100%" w="100%" gap={0}>
      <Stack p="md" pr={0} h="100%" flex={1} gap="sm">
        <TextInput
          label="Search"
          description="*Supports regular expressions"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <SegmentedControl
          data={[
            { label: "Device Name", value: "device_name" },
            { label: "SCADA Name", value: "scada_name" },
          ]}
          value={labelType}
          onChange={setLabelType}
        />
        <Paper p="md" withBorder style={{ flex: 1, overflow: "hidden" }}>
          <ScrollArea style={{ height: "100%", overflowY: "auto" }}>
            <Tree
              data={reducedTreeData ?? []}
              levelOffset={23}
              expandOnClick={false}
              renderNode={(nodeProps) =>
                renderTreeNode({ ...nodeProps, labelType })
              }
              tree={tree}
            />
          </ScrollArea>
        </Paper>
        <Button size="compact-xs" onClick={handleSelectAll}>
          Select All
        </Button>
        <Group gap="sm" grow>
          <Button size="compact-xs" onClick={handleCollapseAll}>
            Collapse All
          </Button>
          {/* <Button
            size="compact-xs"
            onClick={handleExpandAll}
            disabled={filteredTagInts.length > 100}
          >
            Expand All
          </Button> */}
        </Group>
        <Paper p="md" withBorder style={{ flex: 1, overflow: "hidden" }}>
          <ScrollArea style={{ height: "100%", overflowY: "auto" }}>
            {uniqueUnits.map((unit) => (
              <div key={unit}>
                <Text>{unit ?? "Unitless"}</Text>
                {selectedItems
                  .filter((item) => item.unit === unit)
                  .map((item) => (
                    <Checkbox
                      label={item.scada_name}
                      checked={true}
                      py={2}
                      onChange={() => tree.uncheckNode(item.value)}
                      key={item.value}
                    />
                  ))}
                <hr style={{ border: "1px solid grey" }} />
              </div>
            ))}
          </ScrollArea>
        </Paper>
        <Button
          size="compact-xs"
          onClick={() => tree.uncheckNode(rawTreeData[0].value)}
        >
          Clear All
        </Button>
        <DatesProvider settings={{ timezone: "America/Detroit" }}>
          <DatePickerInput
            type="range"
            placeholder="Pick date range"
            allowSingleDateInRange
            value={dateRange}
            onChange={setDateRange}
          />
        </DatesProvider>
        <Button size="compact-xs" onClick={() => refetch()}>
          Fetch Data
        </Button>
      </Stack>
      <Stack p="md" h="100%" flex={3}>
        <CustomCard style={{ height: "100%" }}>
          <PlotlyPlot
            data={unitGroups.flatMap((group) => group.data)}
            layout={layout}
            isLoading={timeSeriesIsLoading}
          />
        </CustomCard>
        <Button
          size="compact-xs"
          onClick={() => handleDownload(timeSeriesData || [])}
        >
          Download Data
        </Button>
      </Stack>
    </Group>
  );
};

export default ProjectData;
