import {
  ActionIcon,
  Affix,
  AppShell,
  Popover,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Outlet, useLocation, useParams } from "react-router-dom";

import { ChatCard, IStep } from "@/components/ChatCard";
import { IconMessageChatbot } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import Header from "./header/Header";
import { NavbarNested } from "./navbar/NavbarNested";
import SmartNav from "./SmartNav";

const Demo = ({
  messages,
  setMessages,
  firstQuestionAsked,
  setFirstQuestionAsked,
}: {
  messages: IStep[];
  setMessages: React.Dispatch<React.SetStateAction<IStep[]>>;
  firstQuestionAsked: boolean;
  setFirstQuestionAsked: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useMantineTheme();

  return (
    // Note: Mantine LoadingOverlay has a zIndex of 400 by default
    <Affix position={{ bottom: 10, right: 20 }} zIndex={500}>
      <Popover
        trapFocus
        position="top-end"
        arrowPosition="center"
        withArrow
        shadow="md"
      >
        <Popover.Target>
          <ActionIcon
            color={theme.primaryColor}
            radius="xl"
            size={40}
            style={{
              boxShadow: "0px 0px 4px 0px black",
            }}
          >
            <IconMessageChatbot stroke={1.5} size={20} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown
          style={{
            padding: 0,
            width: 700,
            // height: 500,
          }}
        >
          <ChatCard
            messages={messages}
            setMessages={setMessages}
            firstQuestionAsked={firstQuestionAsked}
            setFirstQuestionAsked={setFirstQuestionAsked}
          />
        </Popover.Dropdown>
      </Popover>
    </Affix>
  );
};

export function Layout() {
  const [opened, { toggle }] = useDisclosure();
  const [messages, setMessages] = useState<IStep[]>([]);
  const [firstQuestionAsked, setFirstQuestionAsked] = useState(false);
  const location = useLocation();
  const { projectId } = useParams();

  // Clear chat when projectId changes
  useEffect(() => {
    setMessages([]);
    setFirstQuestionAsked(false);
  }, [projectId, setMessages]);

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: { base: 250 },
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding={0}
    >
      <Header opened={opened} toggle={toggle} />
      <NavbarNested />
      <AppShell.Main h="100dvh">
        <div style={{ height: "100%", width: "100%", overflowY: "auto" }}>
          <Outlet />
        </div>
        {location.pathname.includes("/projects/") && (
          <Demo
            messages={messages}
            setMessages={setMessages}
            firstQuestionAsked={firstQuestionAsked}
            setFirstQuestionAsked={setFirstQuestionAsked}
          />
        )}
        <SmartNav />
      </AppShell.Main>
    </AppShell>
  );
}
