import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import "mantine-react-table/styles.css";
import "mapbox-gl/dist/mapbox-gl.css";

import React, { useEffect } from "react";

import { ClerkProvider, useUser } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";
import {
  MantineProvider,
  Modal,
  Text,
  useComputedColorScheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import { ErrorBoundary } from "./ErrorBoundary";

import { GISProvider } from "@/contexts/GISContext";

// Pages
import { ProjectDropdownProvider } from "@/providers/ProjectDropdownProvider";
import { Layout } from "./pages/layout/Layout";
import { SignIn } from "./pages/SignIn";

// Portfolio
import PortfolioHome from "./pages/portfolio/PortfolioHome";
import PortfolioList from "./pages/portfolio/PortfolioList";
import PortfolioMap from "./pages/portfolio/PortfolioMap";

// Project Home
import ProjectHome from "./pages/projects/ProjectHome";

// GIS
import CombinerBlockGIS from "./pages/projects/gis/combiner-block-gis";
import CombinerGIS from "./pages/projects/gis/combiner-gis";
import PCSGIS from "./pages/projects/gis/pcs-gis";
import TrackerBlockGIS from "./pages/projects/gis/tracker-block-gis";
import TrackerGIS from "./pages/projects/gis/tracker-gis";

// Equipment Analysis
import EquipmentAnalysisBESSBlock from "./pages/projects/equipment_analysis/bess_block/page";
import EquipmentAnalysisBESSPCS from "./pages/projects/equipment_analysis/bess_pcs/page";
import EquipmentAnalysisMetStation from "./pages/projects/equipment_analysis/met_station/page";
import EquipmentAnalysisPVDCCombinerBlock from "./pages/projects/equipment_analysis/pv_dc_combiner/block/page";
import EquipmentAnalysisPVDCCombiner from "./pages/projects/equipment_analysis/pv_dc_combiner/page";
import EquipmentAnalysisPVPCS from "./pages/projects/equipment_analysis/pv_pcs/page";
import EquipmentAnalysisSystem from "./pages/projects/equipment_analysis/system/page";
import EquipmentAnalysisTracker from "./pages/projects/equipment_analysis/tracker/page";

// Data Browsing
import ProjectData from "./pages/projects/ProjectData";

// Alerts
import Maximo from "./pages/projects/maximo/Maximo";
import MaximoByAsset from "./pages/projects/maximo/MaximoByAsset";
import EventPage from "./pages/projects/events/EventPage";

// KPIs
import ProjectKPIAlerts from "./pages/projects/kpis/ProjectKPIAlerts";
import ProjectKPIBESSBlockCycleCount from "./pages/projects/kpis/ProjectKPIBESSBlockCycleCount";
import ProjectKPIBESSBlockRestingSOCPercent from "./pages/projects/kpis/ProjectKPIBESSBlockRestingSOCPercent";
import ProjectKPIHome from "./pages/projects/kpis/ProjectKPIHome";
import ProjectKPIPCSEnergyProduction from "./pages/projects/kpis/ProjectKPIPCSEnergyProduction";
import ProjectKPIPCSMechanicalAvailability from "./pages/projects/kpis/ProjectKPIPCSMechanicalAvailability";
import ProjectKPIProjectCycleCount from "./pages/projects/kpis/ProjectKPIProjectCycleCount";
import ProjectKPIProjectEnergyProduction from "./pages/projects/kpis/ProjectKPIProjectEnergyProduction";
import ProjectKPIProjectRestingSOC from "./pages/projects/kpis/ProjectKPIProjectRestingSOC";
import ProjectKPIPVDCCombinerFuseHealth from "./pages/projects/kpis/ProjectKPIPVDCCombinerFuseHealth";

// Reports
import ProjectReports from "./pages/projects/ProjectReports";

// In Development
import ProjectAvailabilityAnalysis from "./pages/projects/ProjectAvailabilityAnalysis";
import ProjectEvents from "./pages/projects/ProjectEvents";
import ProjectLossWaterfall from "./pages/projects/ProjectLossWaterfall";

// Profile
import { Api } from "./pages/Api";
import { Profile } from "./pages/Profile";
import Settings from "./pages/Settings";

// McCarthy Quality
import Inspections from "./pages/projects/mccarthy_quality/Inspections";
import InspectionsGIS from "./pages/projects/mccarthy_quality/InspectionsGIS";
import Observations from "./pages/projects/mccarthy_quality/Observations";
import ObservationsGIS from "./pages/projects/mccarthy_quality/ObservationsGIS";
import QualityHome from "./pages/projects/mccarthy_quality/QualityHome";

// Development
import { useTheme } from "./contexts/ThemeContext";
import ERCOTMap from "./pages/development/ercot-map";
import DevelopmentHome from "./pages/development/home";
import Prices from "./pages/development/prices";
import ResourcePage from "./pages/development/resource-page";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isSignedIn, isLoaded, user } = useUser();
  const [opened, { close }] = useDisclosure(true);

  const { setPrimaryColor } = useTheme();

  useEffect(() => {
    if (user) {
      // Update primary color based on user data
      const parentCompany = user.publicMetadata.parent_company;
      if (parentCompany === "mccarthy") {
        setPrimaryColor("red");
      } else if (parentCompany === "catl") {
        setPrimaryColor("indigo");
      } else if (parentCompany === "excelsior") {
        setPrimaryColor("indigo");
      }
    }
  }, [user, setPrimaryColor]);

  // Auth state has not loaded yet
  if (!isLoaded) {
    return null;
  }

  // Auth state is loaded but user is not signed in
  if (!isSignedIn) {
    return <Navigate to="/sign-in" replace />;
  }

  // If user is not a demo user
  if (!user.publicMetadata.demo) {
    // Auth state is loaded and user is signed in but 2FA is not enabled
    if (!user.twoFactorEnabled) {
      // Redirect to multi-factor authentication page if not already there
      if (window.location.pathname !== "/account/multi-factor") {
        return <Navigate to="/account/multi-factor" replace />;
        // Show modal if user is already on multi-factor authentication page
      } else {
        return (
          <>
            <Modal
              opened={opened}
              onClose={close}
              withCloseButton={false}
              centered
            >
              <div
                style={{
                  textAlign: "justify",
                }}
              >
                <Text>
                  Multi-factor authentication is required to use the
                  application. Please enable multi-factor authentication using
                  your preferred application to continue.
                </Text>
              </div>
            </Modal>
            {children}
          </>
        );
      }
    }
  }

  // Auth state is loaded and user is signed in and 2FA is enabled but user is on
  // multi-factor authentication page
  if (
    user?.twoFactorEnabled &&
    window.location.pathname === "/account/multi-factor"
  ) {
    return <Navigate to="/portfolio/list" replace />;
  }

  // Auth state is loaded and user is signed in and 2FA is enabled
  return children;
};

const ClerkProviderWithRoutes = () => {
  const navigate = useNavigate();
  const redirectUrl = "/portfolio/list";
  const computedColorScheme = useComputedColorScheme("light", {
    getInitialValueInEffect: true,
  });

  return (
    <ClerkProvider
      // If computedColorScheme is "dark", use the dark theme
      appearance={
        computedColorScheme === "dark" ? { baseTheme: dark } : undefined
      }
      publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
      navigate={(to) => navigate(to)}
    >
      <Routes>
        <Route path="/sign-in" element={<SignIn />} />
        {/* Redirect to sign in page of user tries to sign up */}
        <Route path="/sign-up" element={<Navigate to={"/sign-in"} />} />
        <Route
          path=""
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Navigate to={redirectUrl} />} />
          <Route path="/account/*" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/api" element={<Api />} />

          {/* Portfolio */}
          <Route path="/portfolio">
            <Route path="home" element={<PortfolioHome />} />
            <Route path="list" element={<PortfolioList />} />
            <Route path="map" element={<PortfolioMap />} />
          </Route>

          {/* Project */}
          <Route path="/projects/:projectId">
            <Route index element={<ProjectHome />} />

            {/* GIS */}
            <Route path="gis">
              <Route path="pv-dc-combiner">
                <Route index element={<CombinerGIS />} />
                <Route path=":blockId" element={<CombinerBlockGIS />} />
              </Route>
              <Route path="pv-pcs" element={<PCSGIS />} />
              <Route path="tracker">
                <Route index element={<TrackerGIS />} />
                <Route path=":blockId" element={<TrackerBlockGIS />} />
              </Route>
            </Route>

            {/* Equipment Analysis */}
            <Route path="equipment-analysis">
              <Route
                path="bess-block"
                element={<EquipmentAnalysisBESSBlock />}
              />
              <Route path="bess-pcs" element={<EquipmentAnalysisBESSPCS />} />
              <Route
                path="met-station"
                element={<EquipmentAnalysisMetStation />}
              />
              <Route path="pv-dc-combiner">
                <Route index element={<EquipmentAnalysisPVDCCombiner />} />
                <Route path=":combinerId" element={<div>Combiner ID</div>} />
                <Route path="block">
                  <Route
                    index
                    element={<EquipmentAnalysisPVDCCombinerBlock />}
                  />
                </Route>
              </Route>
              <Route path="pv-pcs">
                <Route index element={<EquipmentAnalysisPVPCS />} />
              </Route>
              <Route path="system" element={<EquipmentAnalysisSystem />} />
              <Route path="tracker" element={<EquipmentAnalysisTracker />} />
            </Route>

            {/* Events */}
            <Route path="events">
              <Route index element={<ProjectEvents />} />
              <Route path="event" element={<EventPage />} />
            </Route>

            {/* Maximo */}
            <Route path="maximo">
              <Route index element={<Maximo />} />
              <Route path="by-asset" element={<MaximoByAsset />} />
            </Route>

            {/* KPIs */}
            <Route path="kpis">
              <Route index element={<ProjectKPIHome />} />
              <Route path="alerts" element={<ProjectKPIAlerts />} />
              <Route
                path="bess-block-cycle-count"
                element={<ProjectKPIBESSBlockCycleCount />}
              />
              <Route
                path="bess-block-resting-soc-percent"
                element={<ProjectKPIBESSBlockRestingSOCPercent />}
              />
              <Route
                path="project-cycle-count"
                element={<ProjectKPIProjectCycleCount />}
              />
              <Route
                path="project-energy-production"
                element={<ProjectKPIProjectEnergyProduction />}
              />
              <Route
                path="project-resting-soc-percent"
                element={<ProjectKPIProjectRestingSOC />}
              />
              <Route
                path="pv-dc-combiner-fuse-health"
                element={<ProjectKPIPVDCCombinerFuseHealth />}
              />
              <Route
                path="pv-pcs-energy-production"
                element={<ProjectKPIPCSEnergyProduction />}
              />
              <Route
                path="pv-pcs-mechanical-availability"
                element={<ProjectKPIPCSMechanicalAvailability />}
              />
            </Route>

            {/* Reports */}
            <Route path="reports" element={<ProjectReports />} />

            {/* Data Browsing */}
            <Route path="data-browsing" element={<ProjectData />} />

            {/* McCarthy Quality */}
            <Route path="quality">
              <Route index element={<QualityHome />} />
              <Route path="inspections" element={<Inspections />} />
              <Route path="inspections/gis" element={<InspectionsGIS />} />
              <Route path="observations" element={<Observations />} />
              <Route path="observations/gis" element={<ObservationsGIS />} />
            </Route>

            {/* In Development */}
            <Route path="loss-waterfall" element={<ProjectLossWaterfall />} />
            <Route
              path="availability-analysis"
              element={<ProjectAvailabilityAnalysis />}
            />
          </Route>

          {/* Development */}
          <Route path="/development">
            <Route index element={<ERCOTMap />} />
            <Route path="resources">
              <Route index element={<DevelopmentHome />} />
              <Route path=":resourceId" element={<ResourcePage />} />
            </Route>
            <Route path="prices" element={<Prices />} />
          </Route>
        </Route>
      </Routes>
    </ClerkProvider>
  );
};

export default function App() {
  const { primaryColor } = useTheme();

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          theme={{
            primaryShade: { light: 7, dark: 7 },
            primaryColor: primaryColor,
            breakpoints: { xl: "92em" },
          }}
          defaultColorScheme="auto"
        >
          <ErrorBoundary>
            <Notifications
              autoClose={5000}
              position="bottom-right"
              zIndex={800}
            />
            <ProjectDropdownProvider>
              <GISProvider>
                <ClerkProviderWithRoutes />
              </GISProvider>
            </ProjectDropdownProvider>
          </ErrorBoundary>
        </MantineProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      </QueryClientProvider>
    </BrowserRouter>
  );
}
